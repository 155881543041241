/* custom fonts */
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Roboto:wght@400;900&display=swap");

.App {
    text-align: center;
    font-family: "Roboto", sans-serif;
}

.heavy-text {
    font-weight: 800;
}

.standard-text {
    font-family: "Roboto", sans-serif;
}

.green-text {
    color: darkgreen;
}

.pixel-text {
    font-family: "Press Start 2P", cursive;
}

.rounded-container {
    background: #aaa;
    /* border-radius: 3px; */
    padding: 5px;
    /* margin: 10px; */
    flex: 1;
    display: flex;
}

/* For preventing selection in certain places */
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}
